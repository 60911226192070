.popup {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 199;

  &--show {
    display: flex;
  }

  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: $utility-classes-size * 2;
  }

  &__body {
  }

  &__title {
    font-size: $font-size-large;
  }

  &__close {
    display: inline-block;
    width: $utility-classes-size * 2;
    height: $utility-classes-size * 2;
    background: url('../../images/icons/x.svg') no-repeat center;
    background-size: $utility-classes-size * 3;
    cursor: pointer;
  }

  &__inner {
    border-radius: 12px;
    background-color: #ffffff;
    min-width: 42rem;
    padding: $utility-classes-size * 3;
    box-shadow: $base-box-shadow;
  }

  &__inner--permissions {
    width: 100%;
    max-width: 58rem;
  }
}
