.loading,
.page-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;

  .page-content & {
    height: auto;
    flex: 1;
  }

  &-loader {
    display: block;
    position: relative;

    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #16a085;
    animation: spin 1.7s linear infinite;
    z-index: 11;

    &:before {
      content: '';
      position: absolute;
      top: 5px;
      left: 5px;
      right: 5px;
      bottom: 5px;
      border-radius: 50%;
      border: 3px solid transparent;
      border-top-color: #e74c3c;
      animation: spin-reverse 0.6s linear infinite;
    }

    &:after {
      content: '';
      position: absolute;
      top: 15px;
      left: 15px;
      right: 15px;
      bottom: 15px;
      border-radius: 50%;
      border: 3px solid transparent;
      border-top-color: #f9c922;
      animation: spin 1s linear infinite;
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @keyframes spin-reverse {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0);
    }
  }
}

.popup .page-loading {
  height: auto;

  &-loader {
    width: 50px;
    height: 50px;
  }
}

.loading {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  .loading-loader {
    width: 50px;
    height: 50px;

    &,
    &:before,
    &:after {
      border-width: 2px;
    }
  }
}
