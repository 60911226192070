.notifications {
  position: fixed;
  top: 20rem;
  right: 2rem;
  z-index: 9999;
}

.notification {
  position: relative;
  padding: 1.8rem 4rem;
  padding-left: 6rem;
  border-left: 0.5rem solid transparent;
  width: 40rem;
  max-width: 40rem;
  border-radius: 0.5rem;
  transform: translateX(100%);
  opacity: 0;
  box-shadow: 3px 5px 6px 2px rgba(178, 193, 209, 0.3);
  background: no-repeat center left 1.5rem;
  background-color: #ffffff;
  background-size: 2.6rem;
  transition: opacity 300ms, transform 300ms;
  margin-bottom: 2rem;

  &-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 2rem;
    height: 2rem;
    background: url('../../images/icons/x.svg') no-repeat;
    background-size: 2rem;
    cursor: pointer;
  }

  &-title {
    display: block;
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 0.4rem;
  }

  &-message {
    display: block;
  }

  &--active {
    transform: translateX(0%);
    opacity: 1;
  }

  &--success {
    border-left-color: $color-success;
    background-image: url('../../images/icons/notification/check-circle.svg');
  }

  &--error {
    border-left-color: $color-danger;
    background-image: url('../../images/icons/notification/exclamation-circle.svg');
  }

  &--info {
    border-left-color: $color-dark-primary;
    background-image: url('../../images/icons/notification/info-circle.svg');
  }
}
