.form {
  &,
  &-header,
  &-body,
  &-footer,
  &-container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &-error {
    margin-bottom: 2rem;
    padding: 1.2rem;
    border-radius: 4px;
    font-size: 1.4rem;

    color: #ff0000;
    border: 1px solid #ff0000;
    background-color: rgba(#ff0000, 0.06);
  }
}
