.btn-group {
  display: flex;
  justify-content: center;

  .btn + .btn {
    margin-left: 1rem;
  }

  &--left {
    justify-content: flex-start;
  }

  &--right {
    justify-content: flex-end;
  }
}
