.confirm {
  text-align: center;

  .btn-group {
    margin-top: $utility-classes-size * 2;

    .btn {
      margin: 0 $utility-classes-size;
    }
  }
}
