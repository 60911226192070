.drive,
.drive-items,
.drive-wrapper {
  display: flex;
  flex: 1;
}

.drive-wrapper {
  flex-direction: column;
  padding-top: 2rem;
}

.drive-group {
  padding: 2rem;
  padding-bottom: 0;

  &--empty {
    display: flex;
  }

  &--droppable {
    flex: 1;

    &.is-drag-over {
      box-shadow: inset 2px 2px #4285f4, inset -2px -2px #4285f4, 0 1px 2px rgba(0, 0, 0, 0.1);
      background-color: #e8f0fe;

      * {
        pointer-events: none;
      }
    }
  }

  .empty-state {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;
    font-size: 1.8rem;
  }
}

.drive-wrapper--grid {
  .drive-grid {
    display: grid;
    grid-gap: 0;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    margin-bottom: 2rem;
  }

  .drive-group-title {
    color: $color-dark-primary;
    margin-bottom: 0.5rem;
    margin-left: 2rem;
  }
}

.drive-wrapper--list {
  padding: 2rem;

  .drive-group-title {
    display: none;
  }

  .drive-group {
    padding: 0;
  }
}

.drive-list-header {
  display: flex;
  padding: 2rem;
  border-bottom: 1px solid #dadce0;

  div {
    margin-left: 2rem;
    flex: 1;

    &:first-child {
      padding-left: 1rem;
    }
  }
}
