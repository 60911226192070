.drive-panel {
  background-color: #ffffff;
  border-left: 0.1rem solid #f5f7f9;
  padding: 3rem;
  width: 32rem;

  &-name {
    font-size: $font-size-large;
    margin-bottom: $utility-classes-size * 2;
  }

  &-info {
    display: flex;
    margin-bottom: $utility-classes-size * 1;
    span {
      font-size: $font-size-small;
    }

    span:first-child {
      display: inline-block;
      min-width: 10rem;
      color: $color-dark-primary;
    }
  }

  &-empty-state {
    text-align: center;
    font-size: $font-size-small;
    background: url('../../images/icons/window.svg') no-repeat center;
    background-size: 12rem;
    padding-top: 25rem;
  }

  .permissions-list {
    &-wrapper {
      margin-top: 2rem;
    }

    &-title {
      font-size: 1.4rem;
      font-weight: 500;
      margin-bottom: 0.5rem;
    }

    &-row {
      display: flex;
      align-items: center;
      margin-bottom: 2rem;
    }

    &-permissions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 12rem;
    }

    &-username {
      font-size: 1.4rem;
      margin-right: auto;

      span {
        color: $color-dark-primary;
      }
    }

    &-permission {
      width: 2.5rem;
      height: 2.5rem;
      margin-right: 0.5rem;
      background-repeat: no-repeat;
      background-position: center;
      &:last-child {
        margin-right: 0;
      }

      &--read {
        background-image: url('../../images/icons/eye.svg');
      }

      &--create {
        background-image: url('../../images/icons/folder-plus.svg');
      }

      &--update_delete {
        background-image: url('../../images/icons/tag.svg');
      }

      &--share {
        background-image: url('../../images/icons/folder-symlink.svg');
      }

      &--owner {
        background-image: url('../../images/icons/gear.svg');
      }
    }
  }
}
