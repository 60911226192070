.status-bar {
  position: relative;

  &-toggler {
    width: 3rem;
    height: 3rem;
    background: url('../../images/icons/cloud.svg') no-repeat center;
    background-size: 2.6rem;
    cursor: pointer;

    &-counter {
      width: 2rem;
      height: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      font-size: 1.2rem;
      border-radius: 100%;
      background-color: $color-danger;
      color: #ffffff;
      position: absolute;
      top: -0.6rem;
      right: -0.6rem;
    }
  }

  &-wrapper {
    position: absolute;
    top: calc(100% + 0.8rem);
    right: -0.8rem;
    width: 28rem;
    padding: 1rem 0;
    background-color: #ffffff;
    border: 1px solid #dadada;
    border-radius: 0.6rem;
    box-shadow: 3px 4px 16px 2px rgba(178, 193, 209, 0.4);

    &:before {
      content: '';
      position: absolute;
      top: -0.6rem;
      right: 1.6rem;
      width: 11px;
      height: 11px;
      transform: rotate(45deg);
      background-color: #ffffff;
      border-left: 1px solid #dadada;
      border-top: 1px solid #dadada;
    }
  }

  &-item {
    padding: 0.8rem 2rem;
    font-size: 1.4rem;
    position: relative;

    &-status {
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 100%;
      background-color: $color-dark-secondary;
    }

    &-info {
      position: relative;
      z-index: 10;
    }

    &-title {
      font-size: 1.4rem;
      font-weight: 600;
      padding: 0.2rem 2rem;
    }

    &-divider {
      border: 0.1rem solid #dadce0;
      border-top: 0;
      margin: 0.5rem 0;
    }
  }
}
