//
// COMPONENT: Button

// Changelog:
//   v1.0.0 - initial implementation
// ------------------------

// Variables: basic
$btn-padding: 1rem 1.6rem;
$btn-font-size: 1.6rem;
$btn-line-height: 1.2;
$btn-border-radius: 4px;
$btn-border-size: 1px;

// Variables: button-sizes
$btn-small-font-size: 1.4rem;
$btn-small-padding: 0.8rem 1rem;
$btn-large-font-size: 2rem;
$btn-large-padding: 1.4rem 1.8rem;

// Variables: button variants
$btn-primary: (
  color: #ffffff,
  border: #4782ff,
  background: #4782ff,
  hover-color: #ffffff,
  hover-border: #497ade,
  hover-background: #497ade
);

$btn-secondary: (
  color: #ffffff,
  border: #1b98e0,
  background: #1b98e0,
  hover-color: #ffffff,
  hover-border: #188acc,
  hover-background: #188acc
);

$btn-danger: (
  color: #ffffff,
  border: #e01947,
  background: #e01947,
  hover-color: #ffffff,
  hover-border: #ce0e3a,
  hover-background: #ce0e3a
);

// Mixins: btn-varian
@mixin btn-variant($map) {
  color: map-get($map, color);
  background-color: map-get($map, background);
  border-color: map-get($map, border);

  &:hover {
    color: map-get($map, hover-color);
    background-color: map-get($map, hover-background);
    border-color: map-get($map, hover-border);
  }

  // specify color for loading state, must be important
  &.is-loading {
    &:after {
      border-color: map-get($map, color) transparent map-get($map, color) transparent !important;
    }
  }
}

// Component:
.btn {
  position: relative;
  appearance: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  outline: none;
  border: $btn-border-size solid transparent;
  border-radius: $btn-border-radius;
  padding: $btn-padding;
  font-size: $btn-font-size;
  line-height: $btn-line-height;
  cursor: pointer;

  transition: color 150ms, border-color 150ms, background-color 150ms, box-shadow 150ms;

  // Size variants
  &--small {
    font-size: $btn-small-font-size;
    padding: $btn-small-padding;
  }

  &--large {
    font-size: $btn-large-font-size;
    padding: $btn-large-padding;
  }

  // Color variants
  &--primary {
    @include btn-variant($btn-primary);
  }

  &--secondary {
    @include btn-variant($btn-secondary);
  }

  &--danger {
    @include btn-variant($btn-danger);
  }

  // States
  &.is-disabled {
    cursor: not-allowed;
  }

  &.is-loading {
    cursor: wait;
    color: transparent;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -0.5em;
      margin-left: -0.5em;
      width: 1em;
      height: 1em;
      border-radius: 50%;
      border: 3px solid transparent;
      animation: btn-is-loading-animation 1s linear infinite;
    }
  }
}

@keyframes btn-is-loading-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// Basic usage:
// tags: a, button, div, partly input[type="submit"]
// <div class="btn {btn-variant-class} {btn-state-class}">{text}</div>
