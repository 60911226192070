$base-font-size: 16px;
$font-size-small: 14px;
$font-size-large: 22px;
$base-font-weight: 400;
$base-line-height: 1.3;

// BREAKPOINTS
// -----------
$screen-xs: 319px;
$screen-md: 641px;
$screen-lg: 993px;
$screen-xs-max: $screen-xs - 1;
$screen-md-max: $screen-md - 1;
$screen-lg-max: $screen-lg - 1;

// COLORS
// ------
$color-white: #ffffff;
$color-light-primary: #f2f5f8;
$color-light-secondary: #e3edfd;
$color-light-complementary: #e8eef4;

$color-dark-primary: #363636;
$color-dark-secondary: #c6cfd7;

// STATE COLORS
// ------------
$color-success: #1ea362;
$color-success-hover: darken($color-success, 5%);
$color-alert: #ffd04c;
$color-alert-hover: darken($color-alert, 5%);
$color-danger: #ff0000;
$color-danger-hover: darken($color-danger, 5%);

// UNITS
// -----
$page-padding-x: 2rem;
$page-padding-y: 2rem;
$page-padding: $page-padding-y $page-padding-x;
$utility-classes-size: 0.8rem;

// BOX-SHADOW
// ----------
$base-box-shadow: 3px 4px 16px 2px rgba(178, 193, 209, 0.15);
$base-box-shadow-hover: 3px 4px 16px 2px rgba(178, 193, 209, 0.4);

// FORM
// ----
$form-border-radius: 0.4rem;
$color-light-bg: #ffffff;

// INPUT
// -----
$input-bg: #ffffff;
$input-border: #b2b2b2;
$input-border-radius: 0.3rem;
$input-focus-border: $color-dark-primary;
$input-label-color: #828282;
$input-focus-label-color: $input-focus-border;

// DRIVE
$drive-padding: $utility-classes-size * 2;

// POPUP

// Project variables:
$base-background: #ffffff;
$base-background-secondary: #faf8f6;
$utility-size: 0.8rem;
