.page {
  &-grid {
    display: flex;
    flex-direction: column;
    padding: $page-padding;

    &--login {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 100vh;
      background-color: $base-background-secondary;
    }
  }
}

.page-content {
  display: flex;
  flex-direction: column;
  flex: 1;
}
