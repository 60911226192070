.header {
  position: sticky;
  top: 0;
  z-index: 130;
  background-color: $base-background-secondary;

  &-row {
    display: flex;
    align-items: center;
    padding: $page-padding;
    padding-bottom: 2rem;
    width: 100%;

    &--light {
      background-color: #ffffff;
      border-bottom: 1px solid #dadada;
    }
  }

  &-logo {
    max-width: 6rem;
    margin-right: $utility-size * 4;

    &-externity {
      max-width: 14rem;
    }
  }

  &-finder {
    width: 100%;
    display: none !important;

    input {
      appearance: none;
      width: 100%;
      background-color: #f7f9fa;
      border: 0;
      border-radius: 12px;
      padding: 1.4rem 4rem;
      font-size: 2rem;
      line-height: 1.3;
      outline: none;
      border: 1px solid #f7f9fa;

      transition: border 350ms;

      &:focus {
        border-color: #dadada;
      }
    }
  }

  &-panel {
    position: relative;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    padding-left: $utility-size * 5;
    margin-left: auto;
  }

  &-panel-controls {
    position: absolute;
    top: calc(100% + 1.4rem);
    right: 0.7rem;
    width: 20rem;
    padding: 1rem 0;
    background-color: #ffffff;
    border: 1px solid #dadada;
    border-radius: 0.6rem;
    box-shadow: 3px 4px 16px 2px rgba(178, 193, 209, 0.4);

    &:before {
      content: '';
      position: absolute;
      top: -0.6rem;
      right: 1.6rem;
      width: 11px;
      height: 11px;
      transform: rotate(45deg);
      background-color: #ffffff;
      border-left: 1px solid #dadada;
      border-top: 1px solid #dadada;
    }

    &__item {
      padding: 0.8rem 2rem;
      font-size: 1.4rem;
      cursor: pointer;

      &:hover {
        background-color: $color-light-primary;
      }
    }
  }

  &-user {
    display: flex;
    align-items: center;
    margin-left: 1.5rem;
    padding: 0.5rem 1rem;
    border: 1px solid transparent;
    cursor: pointer;

    &.is-active {
      border-radius: 0.3rem;
      border: 1px solid #dadada;
    }
  }

  &-user-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4rem;
    height: 4rem;
    margin-left: $utility-size * 1;
    line-height: 1;
    border-radius: 50%;
    border: 1px solid #dadada;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1.4rem;
  }

  &__icons {
    margin-left: auto;
    display: flex;
  }

  &__icon {
    width: $utility-classes-size * 4;
    height: $utility-classes-size * 4;
    margin-left: $utility-classes-size;
    background: no-repeat center;
    background-size: $utility-classes-size * 3;
    cursor: pointer;

    &--grid {
      background-image: url('../../images/icons/grid.svg');
    }

    &--list {
      background-image: url('../../images/icons/list.svg');
    }

    &--details {
      background-image: url('../../images/icons/info-circle.svg');
    }
  }
}
