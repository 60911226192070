.breadcrumb {
  display: flex;
  align-items: center;

  &__item {
    font-size: 1.8rem;
    padding: 0.6rem;
    border-radius: 0.3rem;
    text-decoration: none;
    transition: background-color 300ms, opacity 300ms;

    &:hover {
      background-color: #e6e6e6;
    }

    &--home {
      width: $utility-classes-size * 4;
      height: $utility-classes-size * 4;
      background-image: url('../../images/icons/house.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 2.2rem;
      font-size: 0;

      .header--view & {
        display: none;

        & + .breadcrumb__item-divider {
          display: none;
        }
      }
    }

    &-divider {
      margin: 0 0.5rem;
      &:last-child {
        display: none;
      }
    }
  }
}
