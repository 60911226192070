.action {
  &-bar {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    width: 5rem;
    height: 5rem;
    border-radius: 100%;
    background: $color-dark-primary url('../../images/icons/plus-white.svg') no-repeat center;
    background-size: 2.5rem;
    cursor: pointer;
    outline: none;
    transition: background-color 300ms;
    z-index: 999;

    &:hover,
    &:focus {
      background-color: darken($color-dark-primary, 5%);
    }
  }

  &-items {
    position: fixed;
    right: 2rem;
    bottom: 2rem + 6rem;
    padding: 1.2rem 0;
    background-color: #ffffff;
    border-radius: 0.4rem;
    box-shadow: $base-box-shadow-hover;
    max-width: 26rem;
    width: 100%;
    z-index: 999;
  }

  &-item {
    padding: 1rem 2rem;
    padding-left: 5rem;
    font-size: 1.4rem;
    cursor: pointer;

    background-size: 1.9rem;
    background-repeat: no-repeat;
    background-position: center left 1.5rem;

    &:hover {
      background-color: $color-light-primary;
    }
  }
}
