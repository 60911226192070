.upload-bar {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 0.4rem;
  background-color: #3a7bf3;
  color: #ffffff;
  padding: 20px 25px;
}

.upload-bar-back {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
}
