html {
  font-size: 10px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-size: 1.6rem;
  text-rendering: optimizeLegibility;
}

.root {
  font-size: $base-font-size;
  font-weight: $base-font-weight;
  line-height: $base-line-height;
}

* {
  &,
  &:before,
  &:after {
    box-sizing: border-box;
  }
}

a {
  color: inherit;
  cursor: pointer;
}
