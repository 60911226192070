.public-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;

  h2 {
    margin: 0;
    margin-bottom: 0.5rem;
  }

  p {
    margin: 0;
    margin-bottom: 2rem;
  }

  .btn {
    margin-bottom: 2rem;
  }
}
