.context-menu {
  position: absolute;
  top: 0;
  left: 0;
  padding: 1.2rem 0;
  background-color: #ffffff;
  border-radius: 0.4rem;
  box-shadow: $base-box-shadow-hover;
  max-width: 26rem;
  width: 100%;

  &__item {
    padding: 1rem 2rem;
    padding-left: 5rem;
    font-size: 1.4rem;
    cursor: pointer;

    background-size: 1.9rem;
    background-repeat: no-repeat;
    background-position: center left 1.5rem;

    &:hover {
      background-color: $color-light-primary;
    }
  }

  &__item-divider {
    border: 0.1rem solid #dadce0;
    border-top: 0;
    margin: 0.5rem 0;
  }
}

.context-menu__item {
  &--add-folder {
    background-image: url('../../images/icons/folder-plus.svg');
  }

  &--edit {
    background-image: url('../../images/icons/pen.svg');
  }

  &--download {
    background-image: url('../../images/icons/download.svg');
  }

  &--open {
    background-image: url('../../images/icons/collection.svg');
  }

  &--trash {
    background-image: url('../../images/icons/trash.svg');
  }

  &--rename {
    background-image: url('../../images/icons/tag.svg');
  }

  &--folder-image {
    background-image: url('../../images/icons/image.svg');
  }

  &--permissions {
    background-image: url('../../images/icons/gear.svg');
  }

  &--share {
    background-image: url('../../images/icons/folder-symlink.svg');
  }

  &--detail {
    background-image: url('../../images/icons/eye.svg');
  }

  &--none {
    padding-left: 2rem;
    pointer-events: none;
  }
}
