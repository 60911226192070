.login {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &-inner {
    width: 100%;
    max-width: 42rem;
    padding: 4rem 3rem;
    background: #ffffff;
    border-radius: 12px;
  }

  &-forgotten-password {
    margin-top: $utility-classes-size * 3;
    text-align: center;
    color: $color-dark-primary;

    a {
      color: $color-dark-primary;
    }
  }
}
