//
// COMPONENT: Utility classes

// Changelog:
//   v1.0.0 - initial implementation
// ------------------------

// Variables: basic
$utility-size: 0.8rem;
$utility-text-small: 1.4rem;
$utility-text-large: 1.8rem;
$utility-text-secondary: #9b9b9b;

// Margin, padding each generator
@for $i from 0 through 5 {
  // margin top
  .mt-#{$i} {
    margin-top: $i * $utility-size;
  }

  // margin bottom
  .mb-#{$i} {
    margin-bottom: $i * $utility-size;
  }

  // margin left
  .ml-#{$i} {
    margin-left: $i * $utility-size;
  }

  // margin right
  .mr-#{$i} {
    margin-right: $i * $utility-size;
  }

  // padding top
  .pt-#{$i} {
    padding-top: $i * $utility-size;
  }

  // padding bottom
  .pb-#{$i} {
    padding-bottom: $i * $utility-size;
  }

  // padding left
  .pl-#{$i} {
    padding-left: $i * $utility-size;
  }

  // padding right
  .pr-#{$i} {
    padding-right: $i * $utility-size;
  }
}

// Margin auto variants
.mt-auto {
  margin-top: auto;
}

.mb-auto {
  margin-bottom: auto;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

// Text variants
.text-small {
  font-size: $utility-text-small;
}

.text-large {
  font-size: $utility-text-large;
}

.text-secondary {
  color: $utility-text-secondary;
}
