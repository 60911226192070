.drive-item {
  &-icon {
    width: 2rem;
    height: 2rem;
    min-width: 2rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 2rem;
    margin-right: 1rem;

    &--file {
      background-image: url('../../images/icons/drive-icons/file-earmark.svg');
    }

    &--archive {
      background-image: url('../../images/icons/drive-icons/archive.svg');
      padding: 2rem;
    }

    &--document {
      background-image: url('../../images/icons/drive-icons/document-richtext.svg');
    }

    &--folder {
      background-image: url('../../images/icons/drive-icons/folder.svg');
    }

    &--image {
      background-image: url('../../images/icons/drive-icons/image.svg');
    }

    &--media {
      background-image: url('../../images/icons/drive-icons/play.svg');
    }
  }

  &__image {
    img {
      width: 100%;
    }
  }

  &-inner {
    display: flex;
    height: 100%;
    font-size: $font-size-small;
    cursor: pointer;
    transition: background-color 150ms, color 150ms, border-color 150ms, box-shadow 150ms;
  }

  &-column {
    margin-left: 2rem;
    flex: 1;
  }

  &-title {
    flex: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &--list {
    .drive-item-inner {
      padding: 2rem;
      border-bottom: 1px solid #dadce0;
    }
  }

  &--grid {
    padding: 0.7rem;

    .drive-item-inner {
      width: 100%;
      padding: 1.6rem;
      background-color: #ffffff;
      box-shadow: $base-box-shadow;
      border: 1px solid #dadce0;
      border-radius: 0.4rem;
    }
  }

  &.is-drag-over {
    box-shadow: inset 2px 2px #4285f4, inset -2px -2px #4285f4, 0 1px 2px rgba(0, 0, 0, 0.1);
    background-color: #e8f0fe;

    .drive-item-inner {
      pointer-events: none;
    }

    &.drive-item--list {
      box-shadow: inset 2px 2px #4285f4, inset -2px -3px #4285f4, 0 1px 2px rgba(0, 0, 0, 0.1);
    }
  }

  &.is-active {
    .drive-item-inner {
      background-color: $color-light-secondary;
    }

    &.drive-item--grid {
      .drive-item-inner {
        border-color: darken($color-light-secondary, 25%);
      }
    }
  }
}

body.is-dragging {
  .drive-item-inner {
    pointer-events: none;
  }
}
