.item {
  padding: 0.7rem;

  &.is-drag-over {
    box-shadow: inset 2px 2px #4285f4, inset -2px -2px #4285f4, 0 1px 2px rgba(0, 0, 0, 0.1);
    background-color: #e8f0fe;

    * {
      pointer-events: none;
    }
  }

  &-inner {
    cursor: pointer;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: flex-end;
    overflow: hidden;
    background-color: #ffffff;
    border: 0.1rem solid #dadce0;
    border-radius: 0.4rem;
    box-shadow: 3px 4px 16px 2px rgba(178, 193, 209, 0.15);

    transition: border-color 200ms, background-color 200ms;
    &:hover {
      .item-image__description {
        opacity: 0;
      }

      .no-image .item-image__description {
        opacity: 1;
      }
    }
    .is-active & {
      border-color: #6ca1f5;
      background-color: #e3edfd;
    }
  }

  &-image {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-bottom: 56.5%;

    &__description,
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__description {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      text-align: center;
      object-fit: initial;
      font-size: 2.2rem;
      font-weight: 700;
      color: #6ca1f5;
      background-color: rgba(0, 0, 0, 0.8);
      transition: opacity 350ms;
      pointer-events: none;
    }

    &__error {
      background: url('../../images/icons/image.svg') no-repeat center top;
      background-size: 25px;
      padding-top: 35px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      text-align: center;
      width: 100%;
      font-size: 14px;
      color: #000000;
      opacity: 0.5;
    }
  }

  &-icon {
    width: 2rem;
    height: 2rem;
    min-width: 2rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 2rem;
    margin-right: 1rem;

    &--file {
      background-image: url('../../images/icons/drive-icons/file-earmark.svg');
    }

    &--archive {
      background-image: url('../../images/icons/drive-icons/archive.svg');
      padding: 2rem;
    }

    &--document {
      background-image: url('../../images/icons/drive-icons/document-richtext.svg');
    }

    &--folder {
      background-image: url('../../images/icons/drive-icons/folder.svg');
    }

    &--image {
      background-image: url('../../images/icons/drive-icons/image.svg');
    }

    &--media {
      background-image: url('../../images/icons/drive-icons/play.svg');
    }
  }

  &-ext {
    &-jpg,
    &-jpeg,
    &-png,
    &-gif,
    &-webp,
    &-svg {
      background-image: url('../../images/icons/drive-icons/image.svg');
    }

    &-rar,
    &-7zip,
    &-zip {
      background-image: url('../../images/icons/drive-icons/file-earmark-zip.svg');
    }

    &-doc,
    &-docx,
    &-pages,
    &-rtf {
      background-image: url('../../images/icons/drive-icons/document-richtext.svg');
    }

    &-xls,
    &-xlsx,
    &-odt,
    &-numbers {
      background-image: url('../../images/icons/drive-icons/document-richtext.svg');
    }

    &-pdf {
      // TODO: NEW
      background-image: url('../../images/icons/drive-icons/file-post.svg');
    }

    &-avi,
    &-mp4,
    &-mkv,
    &-webm {
      background-image: url('../../images/icons/drive-icons/film.svg');
    }

    &-txt,
    &-text {
      background-image: url('../../images/icons/drive-icons/file-earmark-text.svg');
    }

    &-mp3,
    &-mp4,
    &-acc {
      background-image: url('../../images/icons/drive-icons/music-note-list.svg');
    }
  }

  &-info {
    display: flex;
    align-items: center;
    padding: 1.6rem;
    font-size: 1.4rem;
  }
}
