.item-table {
  width: 100%;
  border-collapse: collapse;

  tr {
    cursor: pointer;
    transition: background-color 200ms;

    &.is-active {
      background-color: #e3edfd;
    }
  }

  td,
  th {
    text-align: left;
    padding: 1.6rem;
  }

  tr > th,
  tr > td {
    border-bottom: 0.1rem solid #dadce0;
  }

  th {
    font-size: 1.5rem;
    font-weight: 500;
  }

  td {
    font-size: 1.4rem;
  }

  .item-info {
    padding: 0;
  }
}
