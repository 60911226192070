.permissions-table {
  margin-bottom: 2rem;

  th,
  td {
    text-align: left;
    width: 100px;
    min-width: 68px;

    &:first-child {
      width: 100%;
      text-align: left;
    }
  }

  .table-cell-wrapper {
    //display: flex;
    //justify-content: flex-end;
  }

  th {
    padding-bottom: 1rem;
    font-size: 1.4rem;
    color: $color-dark-primary;
  }

  td {
    padding-bottom: 1rem;
    font-size: 1.5rem;

    span {
      font-size: 1.3rem;
      font-weight: 600;
    }
  }

  .field {
    margin-bottom: 0;
  }
}
