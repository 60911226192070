//
// COMPONENT: Field

// Changelog:
//   v1.0.0 - initial implementation
// ------------------------

// Variables: basic
$field-padding: 1.1rem 1rem;
$field-background: transparent;
$field-border-size: 1px;
$field-border-color: #cccccc;
$field-border-radius: 4px;
$field-label-font-size: 1.4rem;
$field-label-font-weight: 500;
$field-label-color: #000000;

$field-hover-border-color: #b3b3b3;
$field-active-border-color: #2684ff;

// Variables: field-checkbox
$field-checkbox-size: 22px;

.field {
  margin-bottom: 2rem;

  &-wrapper {
    display: flex;
    flex-direction: column-reverse;
  }

  &-label {
    color: $field-label-color;
    font-size: $field-label-font-size;
    font-weight: $field-label-font-weight;
  }
}

.field--text,
.field--email,
.field--password,
.field--file {
  .field {
    &-control {
      padding: $field-padding;
      border-radius: $field-border-radius;
      border: $field-border-size solid $field-border-color;
      background-color: $field-background;
      transition: border-color 300ms, box-shadow 300ms;

      &:hover {
        border-color: $field-hover-border-color;
      }

      &:hover:focus,
      &:focus {
        outline: none;
        box-shadow: 0 0 0 1px $field-active-border-color;
        border-color: $field-active-border-color;
      }
    }

    &-label {
      margin-bottom: 0.5rem;
    }
  }
}

.field--checkbox,
.field--radio {
  .field {
    &-control {
      display: none;
    }

    &-label {
      position: relative;
      display: inline-flex;
      align-items: center;
      padding-left: $field-checkbox-size + 5px;
      min-height: $field-checkbox-size;
      cursor: pointer;

      &:before,
      &:after {
        content: '';
        position: absolute;
        left: 0;
      }

      &:before {
        top: 0;
        width: $field-checkbox-size;
        height: $field-checkbox-size;
        border: 1px solid $field-border-color;
        border-radius: $field-border-radius;
        transition: background-color 600ms, border-color 300ms;
      }

      &:after {
        top: 50%;
        width: 5px;
        height: 10px;
        border: 2px solid transparent;
        border-left: 0;
        border-top: 0;
        transform: translateY(-50%) translateX(($field-checkbox-size/2) - 2px) rotate(20deg);
        transition: transform 300ms;
      }

      &:hover {
        &:before {
          border-color: $field-hover-border-color;
        }
      }
    }

    &-control:checked + .field-label {
      &:before {
        background-color: $field-active-border-color;
        border-color: $field-active-border-color;
      }

      &:after {
        border-right-color: #ffffff;
        border-bottom-color: #ffffff;
        transform: translateY(-50%) translateX(($field-checkbox-size/2) - 2px) rotate(43deg);
      }
    }
  }
}

.field--radio {
  .field {
    &-label {
      &:before {
        border-radius: 50%;
      }

      &:after {
        width: 10px;
        height: 10px;
        border: 0;
        background-color: #ffffff;
        border-radius: 50%;
        top: 50%;
        transform: translateY(-50%) translateX(($field-checkbox-size/2) - 5px);
      }
    }

    &-control:checked + .field-label {
      &:after {
        transform: translateY(-50%) translateX(($field-checkbox-size/2) - 5px);
      }
    }
  }
}
